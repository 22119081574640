import { useLocation } from "react-router";
import * as React from "react";
import { useEffect } from "react";
import { Loader, LogoIcon, useSubmit } from "@bw/bw-components";
import { ErrorView, UserError } from "../lib/errorView";
import { encode } from "base64-arraybuffer";
import { LoginApi } from "../generated";
import { LoginButton } from "../Welcome";

export function GetCredential() {
    const loginApi = new LoginApi();

    async function getCredential(challenge: string, credentialId: string) {
        const options: CredentialRequestOptions = {
            publicKey: {
                challenge: Uint8Array.from(challenge, (c) => c.charCodeAt(0)),
                allowCredentials: [
                    {
                        id: Buffer.from(credentialId, "base64"),
                        type: "public-key",
                    },
                ],
                userVerification: "discouraged",
            },
        };
        try {
            const credential = (await navigator.credentials.get(options)) as PublicKeyCredential;
            return credential.response as AuthenticatorAssertionResponse;
        } catch (e: any) {
            if (e.name === "NotAllowedError") {
                throw new UserError("Innloggingen ble avbrutt av bruker eller tidsavbrudd");
            }
            throw e as Error;
        }
    }

    async function loginWithWebauthn(credentialId: string, challenge: string) {
        const response = await getCredential(challenge, credentialId);
        const authenticatorData = encode(response.authenticatorData);
        const clientDataJSON = encode(response.clientDataJSON);
        const signature = encode(response.signature);

        await loginApi.verifyCredentials({
            pathParams: {
                credentialId,
            },
            authenticatorResponseDto: { authenticatorData, clientDataJSON, signature },
        });
        window.location.href = "/api/login/redirect";
    }

    const { handleSubmit, updateError, updating } = useSubmit(async () => {
        const query = new URLSearchParams(search);
        const challenge = query.get("challenge");
        const credentialId = query.get("credentialId");
        await loginWithWebauthn(credentialId!, challenge!);
    });

    const { search } = useLocation();

    useEffect(() => {
        handleSubmit();
    }, [search]);

    return (
        <>
            {updating && <Loader />}
            <LogoIcon className="logo" />
            <div className={"button-group"}>
                <ErrorView error={updateError} reload={handleSubmit} />
                <LoginButton skipWebauthn={true} />
            </div>
        </>
    );
}
