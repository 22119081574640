import * as React from "react";
import { PrimaryButton } from "./lib/buttons";
import { LogoIcon } from "@bw/bw-components";
import { useEffect } from "react";

export function Welcome() {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (params.get("use_org_idp") === "true") {
            window.location.hash = "/select-identity-provider";
        }
    }, []);

    return (
        <>
            <LogoIcon className={"logo"} />
            <h1 className={"bw-header"}>Velkommen!</h1>
            <LoginButton />
        </>
    );
}

export function LoginButton({ skipWebauthn }: { skipWebauthn?: boolean }) {
    const targetUrl = "/api/login/start" + (skipWebauthn ? "?skipWebauthn=true" : "");
    function handleLogin(event: React.MouseEvent) {
        event.preventDefault();
        window.location.href = targetUrl;
    }

    return <PrimaryButton onClick={handleLogin}>Logg inn med ID-porten</PrimaryButton>;
}
