import * as React from "react";
import { RedirectedError, RequestError } from "../generated/base";
import { Loader, WarningIcon } from "@bw/bw-components";
import { OutlinedPrimaryButton } from "./buttons";

export class UserError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, UserError.prototype);
    }
}

export function ErrorView({ error, message, reload }: { error?: Error; message?: string; reload?(): void }) {
    if (!error) {
        return null;
    }
    const errorText = message ? message : "En feil har inntruffet.";

    const url = "www.barentswatch.no/kontakt-oss/driftsstatus/";
    const https_url = "https://" + url;

    if (error instanceof RequestError) {
        return (
            <div className="error-requestError">
                <WarningIcon size={"medium"} />
                <div>{errorText}</div>
                <p>
                    Hvis feilen vedvarer, ta kontakt med din organisasjon eller se driftsstatus på{" "}
                    <a style={{ whiteSpace: "nowrap" }} href={https_url}>
                        {url}
                    </a>
                </p>
                {reload && (
                    <p>
                        <OutlinedPrimaryButton onClick={reload}>Prøv igjen</OutlinedPrimaryButton>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof UserError) {
        return (
            <div className="error-requestError">
                <WarningIcon size={"medium"} />
                <div>{error.message}</div>
                <p>
                    Hvis feilen vedvarer, ta kontakt med din organisasjon eller se driftsstatus på{" "}
                    <a style={{ whiteSpace: "nowrap" }} href={https_url}>
                        {url}
                    </a>
                </p>
                {reload && (
                    <p>
                        <OutlinedPrimaryButton onClick={reload}>Prøv igjen</OutlinedPrimaryButton>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof RedirectedError) {
        return <Loader />;
    } else {
        return (
            <div className="error-serverError">
                <div>{errorText}</div>
                <p>
                    Hvis feilen vedvarer, ta kontakt med din organisasjon eller se driftsstatus på{" "}
                    <a style={{ whiteSpace: "nowrap" }} href={https_url}>
                        {url}
                    </a>
                </p>
                {reload && (
                    <p>
                        <OutlinedPrimaryButton onClick={reload}>Prøv igjen</OutlinedPrimaryButton>
                    </p>
                )}
            </div>
        );
    }
}
